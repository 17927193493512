// Library import
import Axios from "axios";
import { useEffect, useState } from "react";
import { Alert, Modal, Spinner } from "react-bootstrap";
import { handleDataLayer, handleClearDataLayer } from "../../utils/datalayer";

// Constant file
const products = [
    // {
    //     id: 1,
    //     price: 85,
    //     name: "Yunnan Baiyao Sport Spray White",
    //     slug: "yunnan-baiyao-sport-spray-white",
    // },
    {
        id: 2,
        price: 37,
        name: "Yunnan Baiyao Sport Gel White",
        slug: "yunnan-baiyao-sport-gel-white",
    },
    // {
    //     id: 3,
    //     price: 42,
    //     name: "Yunnan Baiyao Sport Gel Red",
    //     slug: "yunnan-baiyao-sport-gel-red",
    // },
    // {
    //     id: 4,
    //     price: 23,
    //     name: "Yunnan Baiyao Sport Gel Blue",
    //     slug: "yunnan-baiyao-sport-gel-blue",
    // },
];

const ecommerce = {
    currency: "EUR",
    value: products[0]?.price,
    items: [
        {
            item_id: 2,
            item_name: "Yunnan Baiyao Sport Gel White",
            item_variant: "White",
            price: products[0]?.price,
            quantity: 1,
        },
    ],
};

export const ProductViewModal = ({ show, handleClose }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [carts, setCarts] = useState([]);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!show) resetProcess();

        if (show) {
            handleDataLayer("view_item", ecommerce);
        }
    }, [show]);

    const resetProcess = () => {
        setActiveTab(1);
        setCarts([]);
        setStep(1);
    };

    return (
        <Modal show={show} onHide={handleClose} centered animation={true} className="modal-wrapper" backdrop="static">
            <div className="modal-wrapper p-3 p-sm-5">
                {/* STEP: 01: Product Content */}
                {step === 1 && (
                    <div className="modal-product-content">
                        <div className="row">
                            <div className="col-lg-10">
                                <div className="title px-3 px-sm-0">
                                    <div className="main-title">Product range</div>
                                    <div className="main-text ">
                                        <p>
                                            Experience the plant intelligence of Yunnan Baiyao for <strong>free now</strong>
                                        </p>
                                        <p>
                                            Yunnan Baiyao is part of the official standard equipment of many Olympic delegations and Top Athletes around the world like <strong>Prof. Dr. med. Beat Knechtle.</strong> Since 1995, the Herbal Recipe received the official Chinese award "Most valuable herbal recipe in the whole country"
                                        </p>
                                        <p>
                                            <strong>Experience it for free now.</strong> Receive the first unit at no additional cost when you sign up for the monthly delivery subscription.
                                        </p>
                                        <p>
                                            Additionally, keep in mind that you are protected by our <strong>30-day money-back guarantee.</strong>
                                        </p>
                                        <p className="mb-1">
                                            <strong>More information about subscription:</strong>
                                        </p>
                                        <ul>
                                            <li>
                                                - The first payment will be charged one month after you received your <strong>free shipment</strong> (if this is true)
                                            </li>
                                            <li>
                                                - You can <strong>cancel the subscription</strong> any time
                                            </li>
                                        </ul>

                                        {/* <p>
                                        <strong>Yunnan Baiyao is part of the official standard equipment of many Olympic delegations and Top Athletes around the world like Prof. Dr. med. Beat Knechtle.</strong> Since 1995, the Herbal Recipe received the official Chinese award "Most valuable herbal recipe in the whole country".<br></br><br></br>
                                        <strong>Experience it for free now.</strong> Receive the first unit at no additional cost when you sign up for the monthly delivery subscription. <br /> <br />
                                        <strong>More information about subscription: </strong>
                                        <br />
                                        The first payment will be charged one month after you received your free shipment (if this is true)
                                        <br />
                                        You can cancel the subscription any time
                                        <br />
                                        <br />
                                        Additionally, keep in mind that you are protected by our <strong>30-day money-back guarantee.</strong>
                                    </p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="title-image px-4 px-sm-0">
                                    <img alt="image" src="./images/China-Most-valuable-badge.png" />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-stretch px-4 px-sm-0">
                            {products?.map((product, index) => (
                                <div className="col-lg-12 col-md-12 col-sm-12  mb-4 mb-lg-0" key={index}>
                                    <div className={activeTab === product?.id ? "product active" : "product"} onClick={() => setActiveTab(product?.id)}>
                                        <div className="image">
                                            <img alt="image" src={`./images/product${product?.id}.png`} />
                                        </div>

                                        <h6 className="product-title pt-2">{product?.name}</h6>

                                        {/* <div
                                            className="buy-button"
                                            onClick={() => {
                                                handleAddProductToCart(product?.id);
                                                setActiveTab(product?.id);
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="5" height="8" viewBox="0 0 5 8">
                                                <image id="Triangle" width="5" height="8" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAABqADAAQAAAABAAAACgAAAACNqPKeAAAAbklEQVQIHWO8Jm4sysDA4AnEi7Venv0PpMGACUhKAvFCIJ4GVMQIFgUSIAkYyAAyZgAlmUECyBIgfhoQLwJKMrGAeGggCsjfg64DpuYlNolKoOu2oUuABDtA2pDtKAMKdsPMAkncBmI1oCCIhgMAs5oaZJkHr00AAAAASUVORK5CYII=" />
                                            </svg>
                                            Buy Now
                                        </div> */}
                                    </div>
                                    <div className="action-btn">
                                        <button
                                            onClick={() => {
                                                handleDataLayer("add_to_cart", ecommerce);
                                                window.open("/checkout?hasProduct=true", "_self");
                                            }}
                                            className="custom_button"
                                        >
                                            Buy Now
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="row brading_images">
                    <div className="col-lg-12 m-auto text-center">
                        <div className="image">
                            <img className="mw-100" alt="image" src="./images/icon1.png" />
                        </div>
                        <div className="image">
                            <img className="mw-100" alt="image" src="./images/icon2.png" />
                        </div>
                        <div className="image">
                            <img className="mw-100" alt="image" src="./images/icon3.png" />
                        </div>
                        <div className="image">
                            <img className="mw-100" alt="image" src="./images/icon4.png" />
                        </div>
                    </div>
                </div>

                {loading && (
                    <div className="loading-spinner">
                        <Spinner animation="border" variant="danger" />
                    </div>
                )}

                <div
                    className="close-modal"
                    onClick={() => {
                        handleClose();
                        setStep(1);
                    }}
                >
                    <img src="./images/cross.png" alt="Cross" />
                </div>
            </div>
        </Modal>
    );
};
