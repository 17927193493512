// Library import
import dayJs from 'dayjs';

export const createDate = (date) => {
  return dayJs(date ? date : dayJs());
};

export const formatDate = (date, formatString) => {
  return dayJs(date ? date : dayJs()).format(formatString);
};
