import "./bootstrap";

// import $ from 'jquery';
// window.$ = window.jQuery = $;
import "bootstrap";
import "owl.carousel";

$(function () {
    //Scroll
    $('a[href^="#"]').on("click", function (event) {
        // отменяем стандартное действие
        event.preventDefault();

        var sc = $(this).attr("href"),
            dn = $(sc).offset().top;

        $("html, body").animate({ scrollTop: dn }, 1000);
    });

    var owl = $(".reviews-carousel").owlCarousel({
        loop: false,
        nav: false,
        margin: 10,
        dots: true,
        items: 1,
    });
    owl.owlCarousel();

    /** AJAX - GOOGLE SHEET **/
    $("#lead-form").on("submit", function (e) {
        $("#lead-form-btn").prop("disabled", true).text("Loading...");
        e.preventDefault();
        var postdata = $(this).serialize();
        $.ajax({
            type: "POST",
            url: "https://script.google.com/macros/s/AKfycbx21bnXXIw8qVxwjyknXV9GaiCPGqHcwqASaPLKYLZMZ55pZPEozeNv81mOP2CdaOhLQg/exec",
            data: postdata,
            dataType: "json",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            success: function (json) {
                $("#lead-form.error input").removeClass("active");
                setTimeout(function () {
                    $("#lead-form-btn")
                        .prop("disabled", false)
                        .text("RUSH MY TRIAL");
                    $("#lead-form").removeClass("error").trigger("reset");
                    $("#lead-form .success")
                        .removeClass("d-none")
                        .addClass("d-block");
                }, 200);
            },
        });
    });

    $(".StripeElement iframe").ready(function () {
        console.log("...");
    });
});

// Import sections & pages
import "@/js/sections";
import "@/js/pages";
