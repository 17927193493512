// Library import
import { useState } from "react";
import { createRoot } from "react-dom/client";
import { ProductViewModal } from "../../components/modals";

export const HomePage = () => {
    const [showProductViewModal, setShowProductViewModal] = useState(false);

    return (
        <section className="product-section position-relative" id="product">
            <div className="lines position-absolute start-0 bottom-0">
                <img alt="image" src="./images/product-section-line-graphic.svg" />
            </div>
            <div className="container">
                <div className="row mb-5">
                    <div className="col-lg-10">
                        <div className="title px-3 px-sm-0">
                            <div className="main-title">Product range</div>
                            <div className="main-text ">
                                <p>
                                    Experience the plant intelligence of Yunnan Baiyao for <strong>free now</strong>
                                </p>
                                <p>
                                    Yunnan Baiyao is part of the official standard equipment of many Olympic delegations and Top Athletes around the world like <strong>Prof. Dr. med. Beat Knechtle.</strong> Since 1995, the Herbal Recipe received the official Chinese award "Most valuable herbal recipe in the whole country"
                                </p>
                                <p>
                                    <strong>Experience it for free now.</strong> Receive the first unit at no additional cost when you sign up for the monthly delivery subscription.
                                </p>
                                <p>
                                    Additionally, keep in mind that you are protected by our <strong>30-day money-back guarantee.</strong>
                                </p>
                                <p className="mb-1">
                                    <strong>More information about subscription:</strong>
                                </p>
                                <ul>
                                    <li>
                                        - The first payment will be charged one month after you received your <strong>free shipment</strong> (if this is true)
                                    </li>
                                    <li>
                                        - You can <strong>cancel the subscription</strong> any time
                                    </li>
                                </ul>

                                {/* <p>
                                    <strong>Yunnan Baiyao is part of the official standard equipment of many Olympic delegations and Top Athletes around the world like Prof. Dr. med. Beat Knechtle.</strong> Since 1995, the Herbal Recipe received the official Chinese award "Most valuable herbal recipe in the whole country".<br></br><br></br>
                                    <strong>Experience it for free now.</strong> Receive the first unit at no additional cost when you sign up for the monthly delivery subscription. <br /> <br />
                                    <strong>More information about subscription: </strong>
                                    <br />
                                    The first payment will be charged one month after you received your free shipment (if this is true)
                                    <br />
                                    You can cancel the subscription any time
                                    <br />
                                    <br />
                                    Additionally, keep in mind that you are protected by our <strong>30-day money-back guarantee.</strong>
                                </p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="title-image px-4 px-sm-0">
                            <img alt="image" src="./images/China-Most-valuable-badge.png" />
                        </div>
                    </div>
                </div>
                <div className="row align-items-stretch px-4 px-sm-0">
                    {/* <div className="col-lg-3 col-md-6">
                            <div className="product">
                                <div className="image">
                                    <img
                                        alt="image"
                                        src="./images/product1.png"
                                    />
                                </div>
                                <div className="name">
                                    Yunnan Baiyao Sport Spray White
                                </div>
                                <div className="description">
                                    Temperature neutral 100ml spray or
                                    500ml refill bottle
                                </div>
                                <div
                                    className="button"
                                    onClick={() =>
                                        setShowProductViewModal(true)
                                    }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="6"
                                        height="10"
                                        viewBox="0 0 6 10"
                                    >
                                        <image
                                            id="Triangle"
                                            width="6"
                                            height="10"
                                            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAABqADAAQAAAABAAAACgAAAACNqPKeAAAAbklEQVQIHWO8Jm4sysDA4AnEi7Venv0PpMGACUhKAvFCIJ4GVMQIFgUSIAkYyAAyZgAlmUECyBIgfhoQLwJKMrGAeGggCsjfg64DpuYlNolKoOu2oUuABDtA2pDtKAMKdsPMAkncBmI1oCCIhgMAs5oaZJkHr00AAAAASUVORK5CYII="
                                        />
                                    </svg>
                                    Buy Now
                                </div>
                            </div>
                        </div> */}
                    <div className="col-lg-12 col-md-12">
                        <div className="product">
                            <div className="image">
                                <img alt="image" src="./images/product2.png" />
                            </div>
                            <div className="name">Yunnan Baiyao Sport Gel White</div>
                            <div className="description">Temperature neutral 100ml tube</div>

                            <div className="action-btn pt-3">
                                <button onClick={() => window.open("/checkout?hasProduct=true", "_self")} className="custom_button">
                                    Try Now Free
                                </button>
                            </div>
                            {/* <div
                                    className="button"
                                    onClick={() =>
                                        setShowProductViewModal(true)
                                    }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="6"
                                        height="10"
                                        viewBox="0 0 6 10"
                                    >
                                        <image
                                            id="Triangle"
                                            width="6"
                                            height="10"
                                            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAABqADAAQAAAABAAAACgAAAACNqPKeAAAAbklEQVQIHWO8Jm4sysDA4AnEi7Venv0PpMGACUhKAvFCIJ4GVMQIFgUSIAkYyAAyZgAlmUECyBIgfhoQLwJKMrGAeGggCsjfg64DpuYlNolKoOu2oUuABDtA2pDtKAMKdsPMAkncBmI1oCCIhgMAs5oaZJkHr00AAAAASUVORK5CYII="
                                        />
                                    </svg>
                                    Buy Now
                                </div> */}
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                            <div className="product red">
                                <div className="image">
                                    <img
                                        alt="image"
                                        src="./images/product3.png"
                                    />
                                </div>
                                <div className="name">
                                    Yunnan Baiyao Sport Gel Red
                                </div>
                                <div className="description">
                                    Temperature neutral 100ml tube
                                </div>
                                <div
                                    className="button"
                                    onClick={() =>
                                        setShowProductViewModal(true)
                                    }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="6"
                                        height="10"
                                        viewBox="0 0 6 10"
                                    >
                                        <image
                                            id="Triangle"
                                            width="6"
                                            height="10"
                                            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAABqADAAQAAAABAAAACgAAAACNqPKeAAAAbklEQVQIHWO8Jm4sysDA4AnEi7Venv0PpMGACUhKAvFCIJ4GVMQIFgUSIAkYyAAyZgAlmUECyBIgfhoQLwJKMrGAeGggCsjfg64DpuYlNolKoOu2oUuABDtA2pDtKAMKdsPMAkncBmI1oCCIhgMAs5oaZJkHr00AAAAASUVORK5CYII="
                                        />
                                    </svg>
                                    Buy Now
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="product blue">
                                <div className="image">
                                    <img
                                        alt="image"
                                        src="./images/product4.png"
                                    />
                                </div>
                                <div className="name">
                                    Yunnan Baiyao Sport Gel Blue
                                </div>
                                <div className="description">
                                    Temperature neutral 100ml tube
                                </div>
                                <div
                                    className="button"
                                    onClick={() =>
                                        setShowProductViewModal(true)
                                    }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="6"
                                        height="10"
                                        viewBox="0 0 6 10"
                                    >
                                        <image
                                            id="Triangle"
                                            width="6"
                                            height="10"
                                            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAABqADAAQAAAABAAAACgAAAACNqPKeAAAAbklEQVQIHWO8Jm4sysDA4AnEi7Venv0PpMGACUhKAvFCIJ4GVMQIFgUSIAkYyAAyZgAlmUECyBIgfhoQLwJKMrGAeGggCsjfg64DpuYlNolKoOu2oUuABDtA2pDtKAMKdsPMAkncBmI1oCCIhgMAs5oaZJkHr00AAAAASUVORK5CYII="
                                        />
                                    </svg>
                                    Buy Now
                                </div>
                            </div>
                        </div> */}
                </div>

                {/* Product View Modal */}
                <ProductViewModal show={showProductViewModal} handleClose={() => setShowProductViewModal(false)} />

                <div className="row images">
                    <div className="col-lg-12 m-auto text-center">
                        <div className="image">
                            <img className="mw-100" alt="image" src="./images/icon1.png" />
                        </div>
                        <div className="image">
                            <img className="mw-100" alt="image" src="./images/icon2.png" />
                        </div>
                        <div className="image">
                            <img className="mw-100" alt="image" src="./images/icon3.png" />
                        </div>
                        <div className="image">
                            <img className="mw-100" alt="image" src="./images/icon4.png" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

let element = null;

document.addEventListener("DOMContentLoaded", function (event) {
    if (!element) {
        element = document.getElementById("product_section");
        const root = element && createRoot(element);

        if (element) root.render(<HomePage />);
    }
});
