// Library Import
import { useEffect, useState } from "react";
import Axios from "axios";
import { Toast, Spinner } from "react-bootstrap";

// File Import
import { findShippingFormErrors } from "../../utils/validateForm";
import { euCountries } from "../../utils/countries";

export const UserBillingAddress = ({ data }) => {
    const [errors, setErrors] = useState({});
    const [formData, setFormdata] = useState({});
    const [successMsg, setSuccessMsg] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const { country, address, address2, phone, post_code, state, city } = data;
        setFormdata({
            ...formData,
            country,
            address,
            address2,
            phone,
            post_code,
            state,
            city,
        });
    }, []);

    const handleAddressInputChange = (event) => {
        setFormdata({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleAddressFormSubmit = async (event) => {
        event.preventDefault();
        const { newErrors, hasError } = findShippingFormErrors(formData);

        if (hasError) {
            setErrors(newErrors);
        } else {
            setErrors({});
            try {
                setLoading(true);
                const response = await Axios.post("/api/address", formData);
                if (response.status === 201) {
                    setLoading(false);
                    setSuccessMsg("Billing address updated successfully");
                }
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }
    };
    return (
        <div className="form-block product-block px-0">
            <form name="lead-modal" className="row" id="product-form">
                <div className="form-group">
                    <input type="number" name="phone" placeholder="PHONE NUMBER*" value={formData?.phone || ""} required onChange={(event) => handleAddressInputChange(event)} />
                    {errors?.phone && <div className="invalid-feedback d-block">{errors?.phone}</div>}
                </div>

                <div className="form-group">
                    <input type="text" name="address" value={formData?.address || ""} placeholder="ADDRESS*" required onChange={(event) => handleAddressInputChange(event)} />
                    {errors?.address && <div className="invalid-feedback d-block">{errors?.address}</div>}
                </div>

                <div className="form-group">
                    <input type="text" name="address2" value={formData?.address2 || ""} placeholder="ADDRESS LINE 2" onChange={(event) => handleAddressInputChange(event)} />
                </div>

                <div className="form-group">
                    <input type="text" name="city" value={formData?.city || ""} placeholder="CITY*" required onChange={(event) => handleAddressInputChange(event)} />
                    {errors?.city && <div className="invalid-feedback d-block">{errors?.city}</div>}
                </div>

                <div className="form-group">
                    <input type="text" name="state" value={formData?.state || ""} placeholder="STATE" onChange={(event) => handleAddressInputChange(event)} />
                </div>

                <div className="form-group">
                    <input type="text" name="post_code" value={formData?.post_code || ""} placeholder="POSTAL CODE*" required onChange={(event) => handleAddressInputChange(event)} />
                    {errors?.post_code && <div className="invalid-feedback d-block">{errors?.post_code}</div>}
                </div>

                <div className="form-group">
                    <select name="country" value={formData?.country || ""} required onChange={(event) => handleAddressInputChange(event)}>
                        <option value="">Select Country</option>
                        {euCountries?.map((country, index) => (
                            <option key={index} value={country?.code}>
                                {country?.name}
                            </option>
                        ))}
                    </select>
                    {errors?.country && <div className="invalid-feedback d-block">{errors?.country}</div>}
                </div>

                <Toast bg="success" onClose={() => setSuccessMsg(null)} show={successMsg ? true : false} delay={3000} style={{ width: "80%", margin: "0px 15px" }} autohide>
                    <Toast.Body style={{ color: "white" }}>{successMsg}</Toast.Body>
                </Toast>

                {loading && (
                    <div className="loading-spinner">
                        <Spinner animation="border" variant="danger" />
                    </div>
                )}

                <div className="text-center py-4">
                    <button disabled={loading} className="custom_button d-inline-block" style={{ borderRadius: "5px" }} type="submit" onClick={(event) => handleAddressFormSubmit(event)}>
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};
