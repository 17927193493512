// Email Regex
const EMAIL_REGEX = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

export const findShippingFormErrors = (formData) => {
    const { phone, city, country, address, post_code } = formData;

    const newErrors = {};
    let hasError = false;

    if (!phone || phone === "") {
        newErrors.phone = "Phone is required!";
        hasError = true;
    }
    if (!city || city === "") {
        newErrors.city = "City is required!";
        hasError = true;
    }
    if (!country || country === "") {
        newErrors.country = "Country is required!";
        hasError = true;
    }
    if (!address || address === "") {
        newErrors.address = "Address is required!";
        hasError = true;
    }
    // if (!state || state === "") {
    //     newErrors.state = "State is required!";
    //     hasError = true;
    // }
    if (!post_code || post_code === "") {
        newErrors.post_code = "Post code is required!";
        hasError = true;
    }

    return { newErrors, hasError };
};

export const findRegFormErrors = (formData) => {
    const { name, password, password_confirmation, email } = formData;

    const newErrors = {};
    let hasError = false;

    // name errors
    if (!name || name === "") {
        newErrors.name = "Name is required!";
        hasError = true;
    }
    if (!password || password === "") {
        newErrors.password = "Password is required!";
        hasError = true;
    }
    if (!email || email === "") {
        newErrors.email = "Email is required!";
        hasError = true;
    } else if (email && !EMAIL_REGEX.test(email)) {
        newErrors.email = "Your email is not valid";
        hasError = true;
    }

    if (!password_confirmation || password_confirmation === "") {
        newErrors.password_confirmation = "Password is required!";
        hasError = true;
    } else if (password_confirmation && password_confirmation !== password) {
        newErrors.password_confirmation = "Password doesn't match";
        hasError = true;
    }

    return { newErrors, hasError };
};

export const findUpdateUserFormErrors = (formData) => {
    const { name, password, password_confirmation, email } = formData;

    const newErrors = {};
    let hasError = false;

    // name errors
    if (!name || name === "") {
        newErrors.name = "Name is required!";
        hasError = true;
    }
    // if (!password || password === "") {
    //     newErrors.password = "Field is required!";
    //     hasError = true;
    // }
    if (!email || email === "") {
        newErrors.email = "Email is required!";
        hasError = true;
    } else if (email && !EMAIL_REGEX.test(email)) {
        newErrors.email = "Your email is not valid";
        hasError = true;
    }

    if ((password && !password_confirmation) || password_confirmation === "") {
        newErrors.password_confirmation = "Password is required!";
        hasError = true;
    } else if (password_confirmation && password_confirmation !== password) {
        newErrors.password_confirmation = "Password doesn't match";
        hasError = true;
    }

    return { newErrors, hasError };
};

export const findLoginFormErrors = (formData) => {
    const { password, email } = formData;

    const newErrors = {};
    let hasError = false;

    if (!password || password === "") {
        newErrors.password = "Password is required!";
        hasError = true;
    }
    if (!email || email === "") {
        newErrors.email = "Email is required!";
        hasError = true;
    } else if (email && !EMAIL_REGEX.test(email)) {
        newErrors.email = "Your email is not valid";
        hasError = true;
    }

    return { newErrors, hasError };
};
