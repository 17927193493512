import Axios from "axios";
import { useStripe, useElements, PaymentElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { handleDataLayer } from "../../utils/datalayer";

const stripePromise = loadStripe(`${process.env.MIX_STRIPE_KEY}`);

const products = [
    // {
    //     id: 1,
    //     price: 85,
    //     name: "Yunnan Baiyao Sport Spray White",
    //     slug: "yunnan-baiyao-sport-spray-white",
    // },
    {
        id: 2,
        price: 37,
        name: "Yunnan Baiyao Sport Gel White",
        slug: "yunnan-baiyao-sport-gel-white",
    },
    // {
    //     id: 3,
    //     price: 42,
    //     name: "Yunnan Baiyao Sport Gel Red",
    //     slug: "yunnan-baiyao-sport-gel-red",
    // },
    // {
    //     id: 4,
    //     price: 23,
    //     name: "Yunnan Baiyao Sport Gel Blue",
    //     slug: "yunnan-baiyao-sport-gel-blue",
    // },
];

const ecommerce = {
    currency: "EUR",
    items: [
        {
            item_id: 2,
            item_name: "Yunnan Baiyao Sport Gel White",
            item_variant: "White",
            price: products[0]?.price,
            quantity: 1,
        },
    ],
};

const PaymentComponent = ({ payload, handleSuccess }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState("");

    const handleError = (error) => {
        setLoading(false);
        setErrorMessage(error.message);
    };

    useEffect(() => {
        handleDataLayer("begin_checkout", { ...ecommerce, value: payload?.totalPrice });
        handleSubmitIntent();
    }, []);

    const handleSubmitIntent = async () => {
        try {
            // Create the SetupIntent and obtain clientSecret
            const res = await Axios.get("/api/intent", {
                method: "GET",
            });

            if (res.status === 201) {
                setClientSecret(res?.data?.data?.client_secret);
                // setOrderId(res?.data?.order_id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe) {
            return;
        }

        setLoading(true);
        try {
            // Trigger form validation and wallet collection
            const { error: submitError } = await elements.submit();
            if (submitError) {
                handleError(submitError);
                return;
            }

            setErrorMessage("");

            // Confirm the SetupIntent using the details collected by the Payment Element
            const response = await stripe
                .confirmSetup({
                    elements,
                    clientSecret,
                    confirmParams: {
                        return_url: `${process.env.MIX_APP_URL}`,
                    },
                    redirect: "if_required",
                })
                .then(async (result) => {
                    if (result?.setupIntent) {
                        const { payment_method } = result?.setupIntent;
                        setErrorMessage("");

                        if (payment_method) {
                            try {
                                const subscriptionResponse = await Axios.post("/api/subscriptions", { ...payload, payment_method });

                                if (subscriptionResponse.status === 201) {
                                    handleDataLayer("purhcase", { ...ecommerce, value: payload?.totalPrice, transaction_id: subscriptionResponse?.data?.data?.transaction_id });

                                    setLoading(false);
                                    handleSuccess();
                                    setErrorMessage("");
                                }
                            } catch (error) {
                                handleSubmitIntent();
                                setLoading(false);
                                setErrorMessage(error?.response?.data?.message);
                            }
                        } else {
                            throw new Error("Payment method not found !");
                        }
                    }

                    if (result?.error) {
                        setLoading(false);
                        setErrorMessage(result?.error?.message);
                    }
                });

            if (response?.error) {
                handleError(response.error);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h4 className="text-center mb-3 d-block payment-title">Card Details</h4>

            <PaymentElement />

            {errorMessage && <div className="invalid-feedback d-block text-center pt-4 fw-bold">{errorMessage}</div>}

            <div className="text-center py-4">
                <button className="cart-btn d-inline-block" style={{ borderRadius: "5px", width: "initial" }}>
                    Pay Now
                </button>

                {loading && (
                    <div className="loading-spinner">
                        <Spinner animation="border" variant="danger" />
                    </div>
                )}
            </div>
        </form>
    );
};

const PaymentForm = ({ payload, handleSuccess }) => {
    const options = {
        mode: "setup",
        currency: "eur",
        appearance: {
            variables: {
                fontLineHeight: "1.5",
                borderRadius: "3px",
                colorBackground: "#f8f8f8",
                colorTextPlaceholder: "#b3b3b3",
            },
            rules: {
                ".Input": {
                    border: "1px solid white",
                    boxShadow: "none",
                    height: "50px",
                    color: "#000000",
                },
                ".Input:focus, .Input--invalid": {
                    outline: "0px",
                    borderColor: "white",
                    boxShadow: "none",
                },
                ".Input:disabled, .Input--invalid:disabled": {
                    color: "lightgray",
                },
                ".Label": {
                    // fontWeight: '500'
                },
            },
        },
    };
    return (
        <Elements stripe={stripePromise} options={options}>
            <PaymentComponent payload={payload} handleSuccess={handleSuccess} />
        </Elements>
    );
};
export default PaymentForm;
