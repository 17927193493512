// Library import
import Axios from "axios";
import { useEffect, useState } from "react";
import { Card, Col, Modal, Row, Spinner } from "react-bootstrap";

// File Import
import CrossIcon from "~/images/cross.png";
import { replaceHyphen } from "../../utils/replaceHyphen";
import { formatDate } from "../../utils/day";
import { getTruncateString } from "../../utils/truncateString";

export const OrderDetailsModal = ({ show, handleClose, orderId }) => {
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);

    useEffect(() => {
        fetchOrder();
    }, [orderId]);

    const fetchOrder = async () => {
        try {
            setLoading(true);
            const response = await Axios.get(`/api/orders/${orderId}`);

            if (response.status === 200) {
                setLoading(false);
                setOrder(response?.data?.data);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const handleUpdateStatus = async (event) => {
        const status = event.target.value;

        try {
            setLoading(true);
            const response = await Axios.put(`/api/orders/${orderId}`, { status });

            if (response.status === 204) {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => {
                handleClose();
                setOrder(null);
            }}
            centered
            animation={true}
            className="modal-wrapper"
            backdrop="static"
        >
            <div className="modal-wrapper p-3 p-sm-5">
                {loading && (
                    <div className="loading-spinner">
                        <Spinner animation="border" variant="danger" />
                    </div>
                )}

                <div className="order-wrapper">
                    <Row>
                        <div className="widget-item">
                            <div className="widget-title mb-3">Order details</div>
                            <div className="widget-content">
                                <Row>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>ID: </strong>
                                            <span>#{order?.id}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>Date </strong>
                                            <span>{order?.order_date}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>Customer: </strong>
                                            <span>{order?.customer_name}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>Email: </strong>
                                            <span>{order?.customer_email}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>Status: </strong>
                                            <span>{order?.order_status}</span>
                                        </div>
                                    </Col>
                                    {/* <Col md={6}>
                                        <div className="order-info">
                                            <strong>Stripe Status: </strong>
                                            <span className="badge bg-success">{order?.subscription?.status}</span>
                                        </div>
                                    </Col> */}
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>Paid Amount: </strong>
                                            <span>{order?.amount}</span>
                                            <span>
                                                {(order?.subscription?.status === "trialing") && " (Trial)"}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>Payment method: </strong>
                                            <span>Card</span>
                                        </div>
                                    </Col>
                                    <Col md={6}></Col>

                                    {/* <Col md={6}>
                                        <div className="order-info">
                                            <a href="#" target="_blank" className="badge bg-info p-1 download-btn">
                                                Download
                                            </a>{" "}
                                            (Save as PDF)
                                        </div>
                                    </Col> */}
                                </Row>
                            </div>
                        </div>

                        <div className="widget-item">
                            <div className="widget-title">
                                <h6>ORDER'S PLAN</h6>
                            </div>

                            <div className="widget-content">
                                {order?.subscription ? (
                                    <div className="plan-details">
                                        <div className="plan-info order-modal">
                                            <h5>
                                                Yunnan Baiyao Sport Gel White Yunnan Baiyao Sport Gel Red
                                                {order?.subscription?.name}
                                            </h5>
                                            <h4>EUR &euro;{order?.subscription?.amount}</h4>

                                            {/* <span>Trial period will end on 1st July 2023</span> */}
                                            {order?.subscription?.status === "active" && order?.subscription?.ends_at === null && <span className="renew-text d-block">Your plan renews on {formatDate(order?.subscription?.renew_date, "DD MMMM YYYY")}</span>}

                                            {order?.subscription?.status === "active" && order?.subscription?.ends_at !== null && <span className="renew-text d-block">Your plan will be cancel on {formatDate(order?.subscription?.ends_at, "DD MMMM YYYY")}</span>}

                                            {order?.subscription?.status === "trialing" && <span className="renew-text d-block">Trial period will end on {formatDate(order?.subscription?.trial_ends_at, "DD MMMM YYYY")}.</span>}
                                        </div>

                                        <div className="action-btn change-status">
                                            {/* <button
                                                disabled
                                                className="main-btn d-block"
                                            >
                                                TRIAL PLAN
                                            </button> */}

                                            {/* <span>Change Status</span>
                                            <select name="planStatus" value={order?.order_status || ""} onChange={handleUpdateStatus} id="planStatus" className="form-select">
                                                <option value="trialing">Trial</option>
                                                <option value="active">Active</option>
                                                <option value="incomplete">Incomplete</option>
                                            </select> */}
                                        </div>

                                        {/* <div className="action-btn">
                                            {order?.subscription?.status ===
                                                "active" &&
                                                order?.subscription?.ends_at ===
                                                    null && (
                                                    <button
                                                        onClick={() =>
                                                            setOpenPlanModal(
                                                                true
                                                            )
                                                        }
                                                        className="main-btn d-block"
                                                    >
                                                        CANCEL PLAN
                                                    </button>
                                                )}

                                            {order?.subscription?.status ===
                                                "active" &&
                                                order?.subscription?.ends_at !==
                                                    null && (
                                                    <button
                                                        onClick={
                                                            handleActivatePlan
                                                        }
                                                        className="main-btn d-block"
                                                    >
                                                        ACTIVATE PLAN
                                                    </button>
                                                )}

                                            {order?.subscription?.status ===
                                                "inactive" && (
                                                <button
                                                    disabled
                                                    className="main-btn d-block"
                                                >
                                                    INACTIVE PLAN
                                                </button>
                                            )}
                                            {order?.subscription?.status ===
                                                "trialing" && (
                                                <button
                                                    onClick={() =>
                                                        setOpenPlanModal(true)
                                                    }
                                                    className="main-btn d-block"
                                                >
                                                    CANCEL PLAN
                                                </button>
                                            )}
                                        </div> */}
                                    </div>
                                ) : (
                                    <div className="empty-msg pt-2">You have no subscription plan.</div>
                                )}

                                <Row className="mt-3">
                                    <Col lg={8} md={8} sm={6}>
                                        <Card
                                            bg="light"
                                            text="dark"
                                            className="mb-2"
                                            style={{
                                                borderColor: "#b5b5b5",
                                            }}
                                        >
                                            <Card.Header>Purchased Item</Card.Header>
                                            <Card.Body>
                                                <Card.Title className="fw-bold">{getTruncateString(order?.subscription?.items?.name, 50)}</Card.Title>
                                                <Card.Text>{getTruncateString(order?.subscription?.items?.description, 40)}</Card.Text>
                                            </Card.Body>
                                            <Card.Footer className="text-muted">
                                                <strong>Quantity: </strong>
                                                {order?.subscription?.quantity}
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className="widget-item">
                            <div className="widget-title mb-3">Shipping Address</div>
                            <div className="widget-content">
                                <Row>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>Name: </strong>
                                            <span>{order?.customer_name}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>Email: </strong>
                                            <span>{order?.customer_email}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>Phone: </strong>
                                            <span>{order?.shipping?.phone}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>City: </strong>
                                            <span>{order?.shipping?.city}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>City: </strong>
                                            <span>{order?.shipping?.city}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>State: </strong>
                                            <span>{order?.shipping?.state}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>Postal Code: </strong>
                                            <span>{order?.shipping?.post_code}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="order-info">
                                            <strong>Address: </strong>
                                            <span>
                                                {order?.shipping?.address}
                                                {""}
                                                {order?.shipping?.address2}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Row>
                </div>

                {loading && (
                    <div className="loading-spinner">
                        <Spinner animation="border" variant="danger" />
                    </div>
                )}

                <div
                    className="close-modal"
                    onClick={() => {
                        handleClose();
                        setOrder(null);
                    }}
                >
                    <img src={CrossIcon} alt="Cross" />
                </div>
            </div>
        </Modal>
    );
};
