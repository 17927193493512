import { createRoot } from "react-dom/client";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(`${process.env.MIX_PUBLISH_KEY}`);

export const PaymentSuccess = () => {
    const stripe = useStripe();
    const [message, setMessage] = useState("Payment status is in processing");

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecretForPayment =
            new URLSearchParams(window.location.search).get(
                "payment_intent_client_secret"
            ) || null;

        const clientSecretForSetup =
            new URLSearchParams(window.location.search).get(
                "setup_intent_client_secret"
            ) || null;

        const paymentIntentId =
            new URLSearchParams(window.location.search).get("payment_intent") ||
            null;

        const setupIntentId =
            new URLSearchParams(window.location.search).get("setup_intent") ||
            null;

        if (clientSecretForPayment) {
            stripe
                .retrievePaymentIntent(clientSecretForPayment)
                .then(({ paymentIntent }) => {
                    switch (paymentIntent.status) {
                        case "succeeded":
                            Axios.post("/complete-order", {
                                id: paymentIntentId,
                            });
                            setMessage(
                                "Success! Your payment method has been saved."
                            );
                            break;

                        case "processing":
                            setMessage(
                                "Processing payment details. We'll update you when processing is complete."
                            );
                            break;

                        case "requires_payment_method":
                            setMessage(
                                "Failed to process payment details. Please try another payment method."
                            );
                            break;
                    }
                });
        }

        if (clientSecretForSetup) {
            stripe
                .retrieveSetupIntent(clientSecretForSetup)
                .then(({ setupIntent }) => {
                    switch (setupIntent.status) {
                        case "succeeded":
                            Axios.post("/complete-order", {
                                id: setupIntentId,
                            });
                            setMessage(
                                "Success! Your payment method has been saved."
                            );
                            break;

                        case "processing":
                            setMessage(
                                "Processing payment details. We'll update you when processing is complete."
                            );
                            break;

                        case "requires_payment_method":
                            setMessage(
                                "Failed to process payment details. Please try another payment method."
                            );
                            break;
                    }
                });
        }
    }, [stripe]);

    return (
        <div
            className="modal-wrapper success-process d-flex justify-content-center align-items-center text-center"
            style={{ height: "100vh" }}
        >
            <div>
                <img src="./images/success.png" alt="" />
                <p className="pt-3">{message}</p>

                <div className="text-center py-4">
                    <button
                        className="cart-btn d-inline-block"
                        onClick={() => window.open("/", "_self")}
                        style={{
                            borderRadius: "5px",
                            width: "initial",
                        }}
                    >
                        Go Home
                    </button>
                </div>
            </div>
        </div>
    );
};

let element = null;

document.addEventListener("DOMContentLoaded", function (event) {
    if (!element) {
        element = document.getElementById("PaymentSuccess");
        const root = element && createRoot(element);

        if (element)
            root.render(
                <Elements stripe={stripePromise}>
                    <PaymentSuccess />
                </Elements>
            );
    }
});
