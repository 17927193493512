// Library Import
import { useEffect, useState } from "react";
import { Card, Col, Row, Spinner, Button } from "react-bootstrap";
import { createRoot } from "react-dom/client";
import Axios from "axios";
import dayjs from "dayjs";

// File Import
import SiteLogo from "~/images/logo.png";
import HomeIcon from "~/images/house.png";
import { CancelSubscription, RenewSubscription, UserAccountInfo, UserBillingAddress } from "../../components";
import { getTruncateString } from "../../utils/truncateString";
import UpdatePayment from "../../components/payment/UpdatePayment";
import { replaceHyphen } from "../../utils/replaceHyphen";
import { formatDate } from "../../utils/day";

export const Settings = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormdData] = useState({});
    const [addressFormdata, setAddressFormdata] = useState(null);
    const [card, setCard] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [openRenewModal, setOpenRenewModal] = useState(false);
    const [openPlanModal, setOpenPlanModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        fetchBillingAddress();
        fetchCards();
        fetchSubscription();
        fetchInvoices();
        fetchUsers();
    }, []);

    const fetchBillingAddress = async () => {
        try {
            setLoading(true);
            const response = await Axios.get("/api/address");

            if (response.status === 200) {
                setLoading(false);
                setAddressFormdata(response?.data?.data);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await Axios.get("/api/users");

            if (response.status === 200) {
                setLoading(false);
                setUser(response?.data?.data);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const fetchCards = async () => {
        try {
            setLoading(true);
            const response = await Axios.get("/api/cards");

            if (response.status === 200) {
                setLoading(false);
                setCard(response?.data?.data);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const fetchInvoices = async () => {
        try {
            const response = await Axios.get("/api/invoices");

            if (response.status === 201) {
                setInvoices(response?.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchSubscription = async () => {
        try {
            setLoading(true);
            const response = await Axios.get("/api/subscriptions");

            if (response.status === 201) {
                setLoading(false);
                setSubscription(response?.data?.data);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const handleActivatePlan = async () => {
        try {
            setLoading(true);
            const response = await Axios.put("/api/subscriptions");

            if (response.status === 204) {
                setLoading(false);
                window.location.reload();
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <div className="panel-wrapper">
            <Row>
                <Col lg={5} className="d-none d-lg-block" style={{ background: "rgb(83, 96, 126)" }}>
                    <div className="panel-intro">
                        <div className="panel-intro-inner text-center">
                            <div className="site-logo">
                                <img src={SiteLogo} alt="Site logo" />
                            </div>

                            <a href="/" className="redirect-home">
                                <img src={HomeIcon} alt="House" />
                                Home
                            </a>
                            {/* <div className="author-name">John Doe</div> */}
                        </div>
                    </div>
                </Col>
                <Col lg={7} className="position-relative">
                    <div className="logout-action d-none d-lg-flex justify-content-end px-5 pt-4">
                        <a href="/logout">Logout</a>
                    </div>
                    <div className="panel-content p-3 p-md-5 mt-4">
                        {step === 1 && (
                            <div className="step-one">
                                <div className="widget-item">
                                    <div className="widget-title d-flex align-items-center justify-content-between">
                                        <h6 className="mb-0">CURRENT PLAN</h6>
                                        <div className="logout-action d-flex d-lg-none justify-content-end">
                                            <a style={{ fontSize: "14px" }} href="/logout">
                                                Logout
                                            </a>
                                        </div>
                                    </div>

                                    <div className="widget-content">
                                        {subscription ? (
                                            <>
                                                <div className="plan-details">
                                                    <div className="plan-info">
                                                        <h5>{replaceHyphen(subscription?.plan_name)}</h5>
                                                        <h4>EUR &euro;{subscription?.amount.toLocaleString('en-DE', {minimumFractionDigits:2, maximumFractionDigits:2})}</h4>
                                                        {/* {subscription?.status === "active" && subscription?.ends_at === null && <span className="renew-text d-block">Your plan renews on {formatDate(subscription?.renew_date, "DD MMMM YYYY")}</span>} */}

                                                        {subscription?.status === "active" && subscription?.ends_at === null && <span className="renew-text d-block">Your plan renews on {formatDate(subscription?.renew_date, "DD MMMM YYYY")}</span>}

                                                        {subscription?.status === "trialing" && subscription?.ends_at === null && <span className="renew-text d-block">Your trail period ends on {formatDate(subscription?.trial_ends_at, "DD MMMM YYYY")}.</span>}

                                                        {((subscription?.status === "trialing" && subscription?.ends_at !== null) || subscription?.status === "cancelled" || (subscription?.status === "active" && subscription?.ends_at !== null)) && <span className="renew-text d-block">Your plan is cancelled on {formatDate(subscription?.ends_at, "DD MMMM YYYY")}.</span>}
                                                    </div>

                                                    <div className="action-btn">
                                                        {((subscription?.status === "trialing" && subscription?.ends_at === null) || (subscription?.status === "active" && subscription?.ends_at === null)) && (
                                                            <button onClick={() => setOpenPlanModal(true)} className="main-btn d-block">
                                                                CANCEL PLAN
                                                            </button>
                                                        )}

                                                        {((subscription?.status === "trialing" && subscription?.ends_at !== null) || subscription?.status === "cancelled" || (subscription?.status === "active" && subscription?.ends_at !== null)) && (
                                                            <button onClick={() => setOpenRenewModal(true)} className="main-btn d-block">
                                                                RENEW PLAN
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="mt-3" style={{ maxWidth: "300px" }}>
                                                    <Card
                                                        bg="light"
                                                        text="dark"
                                                        className="mb-2"
                                                        style={{
                                                            borderColor: "#b5b5b5",
                                                        }}
                                                    >
                                                        <Card.Header>Purchased Item</Card.Header>
                                                        <Card.Body>
                                                            <div className="card_thumb" style={{ width: "100%", height: "130px", textAlign: "center", margin: "0 auto 15px" }}>
                                                                <img style={{ height: "100%" }} alt="image" src={`./images/product2.png`} />
                                                            </div>
                                                            <Card.Title className="fw-bold">{getTruncateString(subscription?.items?.name, 50)}</Card.Title>
                                                            <Card.Text>{getTruncateString(subscription?.items?.description, 40)}</Card.Text>
                                                        </Card.Body>
                                                        <Card.Footer className="text-muted">
                                                            <strong>Quantity: </strong>
                                                            {subscription?.quantity}
                                                        </Card.Footer>
                                                    </Card>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="empty-msg pt-2">You have no subscription plan.</div>
                                        )}
                                    </div>
                                </div>

                                <div className="widget-item">
                                    <div className="widget-title">
                                        <h6>PAYMENT METHOD</h6>
                                    </div>

                                    <div className="widget-content payment-method table-responsive">
                                        {card ? (
                                            <div className="mt-1 mb-2 d-flex align-items-center">
                                                <div className="me-3">
                                                    **** **** **** {card?.last4}
                                                    <span className="ms-2 badge bg-secondary">{card?.brand}</span>
                                                </div>
                                                <div>
                                                    Expires {card?.expire_month}/{card?.expire_year}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="empty-msg pt-2 mb-3">You have no payment method.</div>
                                        )}

                                        <div className="inline-action d-flex align-items-center">
                                            <svg margin="[object Object]" aria-hidden="true" className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--add-svg Icon-color-svg Icon-color--gray-svg" height="12" width="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 7h6a1 1 0 0 1 0 2H9v6a1 1 0 0 1-2 0V9H1a1 1 0 1 1 0-2h6V1a1 1 0 1 1 2 0z" fillRule="evenodd"></path>
                                            </svg>
                                            <h6 className="ms-1" onClick={() => setStep(2)}>
                                                Add new card
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget-item">
                                    <div className="widget-title">
                                        <h6>BILLING AND SHIPPING INFORMATION</h6>
                                    </div>

                                    <div className="widget-content payment-method">
                                        <div className="inline-action d-flex mt-3 align-items-center">
                                            <svg margin="[object Object]" aria-hidden="true" className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--edit-svg Icon-color-svg Icon-color--gray-svg" height="12" width="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.194 4.28 11.72.805l.087-.087a2.456 2.456 0 1 1 3.473 3.473zm-1.415 1.414-9.264 9.264L0 16l1.042-4.515 9.264-9.264z" fillRule="evenodd"></path>
                                            </svg>
                                            <h6 className="ms-1" onClick={() => setStep(3)}>
                                                Update Information
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget-item">
                                    <div className="widget-title">
                                        <h6>USER ACCOUNT INFORMATION</h6>
                                    </div>

                                    <div className="widget-content payment-method">
                                        <div className="inline-action d-flex mt-3 align-items-center">
                                            <svg margin="[object Object]" aria-hidden="true" className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--edit-svg Icon-color-svg Icon-color--gray-svg" height="12" width="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.194 4.28 11.72.805l.087-.087a2.456 2.456 0 1 1 3.473 3.473zm-1.415 1.414-9.264 9.264L0 16l1.042-4.515 9.264-9.264z" fillRule="evenodd"></path>
                                            </svg>
                                            <h6 className="ms-1" onClick={() => setStep(4)}>
                                                Update Information
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget-item">
                                    <div className="widget-title">
                                        <h6>INVOICE HISTORY</h6>
                                    </div>

                                    <div className="widget-content payment-method table-responsive">
                                        {invoices ? (
                                            <table className="table mt-1 mb-2">
                                                <tbody>
                                                    {invoices?.map((invoice, index) => (
                                                        <tr key={index}>
                                                            <th>{invoice?.created_at}</th>
                                                            <th>{invoice?.amount_paid}</th>
                                                            <td>
                                                                <span className="badge bg-success">{invoice?.status}</span>
                                                            </td>
                                                            <td>{getTruncateString(invoice?.subscription_name, 40)}</td>

                                                            <td>
                                                                <a href={`/invoices/download/${invoice?.id}`} className="download-btn badge bg-info" target="_blank">
                                                                    Download
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="empty-msg pt-2 mb-3">You have no invoice.</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {step === 2 && (
                            <div className="step-two">
                                <div
                                    className="back-step"
                                    onClick={() => {
                                        setStep(1);
                                        fetchCards();
                                    }}
                                >
                                    &#8592; Back
                                </div>
                                <div className="step-title">Add new card</div>

                                <UpdatePayment handleSuccess={() => setStep(5)} />
                            </div>
                        )}

                        {step === 3 && (
                            <div className="step-two">
                                <div
                                    className="back-step"
                                    onClick={() => {
                                        setStep(1);
                                        fetchBillingAddress();
                                    }}
                                >
                                    &#8592; Back
                                </div>
                                <div className="step-title">Update Billing Address</div>

                                <UserBillingAddress data={addressFormdata} />
                            </div>
                        )}

                        {step === 4 && (
                            <div className="step-two">
                                <div
                                    className="back-step"
                                    onClick={() => {
                                        setStep(1);
                                        fetchUsers();
                                    }}
                                >
                                    &#8592; Back
                                </div>
                                <div className="step-title">Update User Information</div>

                                <UserAccountInfo user={user} />
                            </div>
                        )}

                        {step === 5 && (
                            <div className="step-two">
                                <div
                                    className="back-step"
                                    onClick={() => {
                                        setStep(1);
                                        fetchCards();
                                    }}
                                >
                                    &#8592; Back
                                </div>

                                <div className="success-process d-flex justify-content-center align-items-center text-center">
                                    <div>
                                        <img src="./images/success.png" alt="" />
                                        <p className="mt-3">Your card has been successfully added</p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {loading && (
                            <div className="loading-spinner">
                                <Spinner animation="border" variant="danger" />
                            </div>
                        )}

                        {openPlanModal && (
                            <CancelSubscription
                                show={openPlanModal}
                                handleClose={() => setOpenPlanModal(false)}
                                handleSuccess={() => {
                                    fetchSubscription();
                                }}
                            />
                        )}

                        {openRenewModal && (
                            <RenewSubscription
                                show={openRenewModal}
                                handleClose={() => setOpenRenewModal(false)}
                                handleSuccess={() => {
                                    fetchSubscription();
                                }}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

let element = null;

document.addEventListener("DOMContentLoaded", function (event) {
    if (!element) {
        element = document.getElementById("settings");
        const root = element && createRoot(element);

        // Fetch exisiting data via Props
        // const user = element?.dataset?.user && JSON.parse(element.dataset.user);

        if (element) root.render(<Settings />);
    }
});
