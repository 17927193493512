// Library Import
import { useEffect, useState } from "react";
import { Card, Col, Row, Spinner, Button } from "react-bootstrap";
import { createRoot } from "react-dom/client";
import Axios from "axios";
import dayjs from "dayjs";

// File Import
import SiteLogo from "~/images/logo.png";
import HomeIcon from "~/images/house.png";
import { OrderDetailsModal } from "../../components";
import { formatDate } from "../../utils/day";

//TODO: Date format
// console.log(dayjs('2023-05-04T11:12:35.000000Z').format('DD MMMM YYYY'));

export const Admin = ({ user }) => {
    const [step, setStep] = useState(1);
    const [orderModal, setOrderModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orders, setOrder] = useState(null);
    const [orderId, setOrderId] = useState(null);

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            setLoading(true);
            const response = await Axios.get("/api/orders");

            if (response.status === 200) {
                setLoading(false);
                setOrder(response?.data?.data?.data);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <div className="panel-wrapper">
            <Row>
                <Col lg={4} className="d-none d-lg-block" style={{ background: "rgb(83, 96, 126)" }}>
                    <div className="panel-intro">
                        <div className="panel-intro-inner text-center">
                            <div className="site-logo">
                                <img src={SiteLogo} alt="Site logo" />
                            </div>

                            <a href="/" className="redirect-home">
                                <img src={HomeIcon} alt="House" />
                                Home
                            </a>
                        </div>
                    </div>
                </Col>
                <Col lg={8} className="position-relative">
                    <div className="logout-action d-flex justify-content-end px-5 pt-4">
                        <a href="/logout">Logout</a>
                    </div>
                    <div className="panel-content p-3 p-md-5 mt-4">
                        {step === 1 && (
                            <div className="step-one">
                                <div className="widget-item">
                                    <div className="widget-title">
                                        <h6>ORDER HISTORY</h6>
                                    </div>

                                    <div className="widget-content payment-method table-responsive">
                                        {orders ? (
                                            <table className="table mt-1 mb-2">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Customer</th>
                                                        <th>Status</th>
                                                        <th>Date</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orders?.map((order, index) => (
                                                        <tr key={index}>
                                                            <th>{order?.id}</th>
                                                            <td>{order?.user?.name}</td>
                                                            <td>
                                                                <span className="badge bg-success">{order?.status}</span>
                                                            </td>

                                                            <td>{formatDate(order?.created_at, "DD MMMM YYYY")}</td>

                                                            <td>
                                                                <span
                                                                    className="badge bg-info"
                                                                    onClick={() => {
                                                                        setOrderId(order?.id);
                                                                        setOrderModal(true);
                                                                    }}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    Details
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="empty-msg pt-2 mb-3">You have no invoice.</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {loading && (
                            <div className="loading-spinner">
                                <Spinner animation="border" variant="danger" />
                            </div>
                        )}
                    </div>
                </Col>
            </Row>

            {orderId && <OrderDetailsModal orderId={orderId} show={orderModal} handleClose={() => setOrderModal(false)} />}
        </div>
    );
};

let element = null;

document.addEventListener("DOMContentLoaded", function (event) {
    if (!element) {
        element = document.getElementById("admin");
        const root = element && createRoot(element);

        // Fetch exisiting data via Props
        const user = element?.dataset?.user && JSON.parse(element.dataset.user);

        if (element) root.render(<Admin user={user} />);
    }
});
