import Axios from "axios";
import { useStripe, useElements, PaymentElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

const stripePromise = loadStripe(`${process.env.MIX_STRIPE_KEY}`);

const PaymentComponent = ({ handleSuccess }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState("");

    const handleError = (error) => {
        setLoading(false);
        setErrorMessage(error.message);
    };

    useEffect(() => {
        handleSubmitIntent();
    }, []);

    const handleSubmitIntent = async () => {
        try {
            // Create the SetupIntent and obtain clientSecret
            const res = await Axios.get("/api/intent", {
                method: "GET",
            });

            if (res.status === 201) {
                setClientSecret(res?.data?.data?.client_secret);
                // setOrderId(res?.data?.order_id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe) {
            return;
        }

        setLoading(true);
        try {
            // Trigger form validation and wallet collection
            const { error: submitError } = await elements.submit();
            if (submitError) {
                handleError(submitError);
                return;
            }

            setErrorMessage("");

            // Confirm the SetupIntent using the details collected by the Payment Element
            const response = await stripe
                .confirmSetup({
                    elements,
                    clientSecret,
                    confirmParams: {
                        return_url: `${process.env.MIX_APP_URL}`,
                    },
                    redirect: "if_required",
                })
                .then(async (result) => {
                    if (result) {
                        const { payment_method } = result?.setupIntent;

                        if (payment_method) {
                            try {
                                const subscriptionResponse = await Axios.post("/api/cards", { payment_method });

                                if (subscriptionResponse.status === 201) {
                                    setLoading(false);
                                    handleSuccess();
                                }
                            } catch (error) {
                                handleSubmitIntent();
                                setLoading(false);
                                console.log(error);
                            }
                        } else {
                            throw new Error("Payment method not found !");
                        }
                    }
                });

            if (response?.error) {
                handleError(response.error);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>

            <PaymentElement/>

            <div className="text-center py-4">
                <button className="custom_button d-inline-block" style={{ borderRadius: "5px", width: "initial" }}>
                    Add Card
                </button>

                {loading && (
                    <div className="loading-spinner">
                        <Spinner animation="border" variant="danger" />
                    </div>
                )}
            </div>
            {errorMessage && <div className="invalid-feedback d-block text-center pt-1 pb-5">{errorMessage}</div>}
        </form>
    );
};

const UpdatePayment = ({ handleSuccess }) => {
    const options = {
        mode: "setup",
        currency: "eur",
        appearance: {
            variables: {
              fontLineHeight: '1.5',
              borderRadius: '3px',
              colorBackground: '#f8f8f8',
              colorTextPlaceholder: '#b3b3b3'
            },
            rules: {
              '.Input': {
                border: '1px solid white',
                boxShadow: 'none',
                height: '50px',
                color: '#000000'
              },
              '.Input:focus, .Input--invalid': {
                outline: '0px',
                borderColor: 'white',
                boxShadow: 'none'
              },
              '.Input:disabled, .Input--invalid:disabled': {
                color: 'lightgray'
              },
              '.Label': {
                // fontWeight: '500'
              }
            }
        }
    };
    return (
        <Elements stripe={stripePromise} options={options}>
            <PaymentComponent handleSuccess={handleSuccess} />
        </Elements>
    );
};
export default UpdatePayment;
