// Library import
import Axios from "axios";
import { createRoot } from "react-dom/client";
import { useEffect, useState } from "react";
import { Alert, Modal, Spinner } from "react-bootstrap";
import { findShippingFormErrors, findLoginFormErrors, findRegFormErrors } from "../../utils/validateForm";
import PaymentForm from "../../components/payment/PaymentForm";
import { handleDataLayer } from "../../utils/datalayer";
import { euCountries } from "../../utils/countries";

// Constant file
const products = [
    // {
    //     id: 1,
    //     price: 85,
    //     name: "Yunnan Baiyao Sport Spray White",
    //     slug: "yunnan-baiyao-sport-spray-white",
    // },
    {
        id: 2,
        price: 37,
        name: "Yunnan Baiyao Sport Gel White",
        slug: "yunnan-baiyao-sport-gel-white",
    },
    // {
    //     id: 3,
    //     price: 42,
    //     name: "Yunnan Baiyao Sport Gel Red",
    //     slug: "yunnan-baiyao-sport-gel-red",
    // },
    // {
    //     id: 4,
    //     price: 23,
    //     name: "Yunnan Baiyao Sport Gel Blue",
    //     slug: "yunnan-baiyao-sport-gel-blue",
    // },
];

const ecommerce = {
    currency: "EUR",
    value: products[0]?.price,
    items: [
        {
            item_id: 2,
            item_name: "Yunnan Baiyao Sport Gel White",
            item_brand: "Yunnan Baiyao",
            item_variant: "White",
            price: products[0]?.price,
            quantity: 1,
        },
    ],
};

export const CheckoutSection = () => {
    const [serverError, setServerError] = useState("");
    const [activeTab, setActiveTab] = useState(1);
    const [carts, setCarts] = useState([]);
    const [step, setStep] = useState(2);
    const [addressFormData, setAddressFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [regFormData, setRegFormData] = useState({});
    const [loginFormData, setLoginFormData] = useState({});
    const [isAccountExist, setIsAccountExist] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);

    useEffect(() => {
        if (window.location.search !== "") {
            const searchParams = new URLSearchParams(document.location.search);
            const hasProduct = searchParams.get("hasProduct") || false;

            if (hasProduct === "true")
                setCarts([
                    {
                        id: 2,
                        price: 37,
                        name: "Yunnan Baiyao Sport Gel White",
                        slug: "yunnan-baiyao-sport-gel-white",
                        quantity: 1,
                        totalPrice: 37,
                    },
                ]);
        }

        handleDataLayer("view_cart", ecommerce);

        return () => {
            resetProcess();
        };
    }, []);

    useEffect(() => {
        setErrors({});
    }, [step]);

    const resetProcess = () => {
        setActiveTab(1);
        setCarts([]);
        setStep(1);
        setAddressFormData({});
        setErrors({});
    };

    const handleAddProductToCart = (productId) => {
        products?.forEach((product) => {
            if (product?.id === productId) {
                const hasItemIndex = carts?.findIndex((cart) => cart?.id === product?.id);

                if (hasItemIndex === -1) {
                    setCarts((prevState) => [
                        ...prevState,
                        {
                            ...product,
                            quantity: 1,
                            totalPrice: product?.price,
                        },
                    ]);
                } else {
                    const copyCarts = [...carts];
                    copyCarts[hasItemIndex] = {
                        ...product,
                        quantity: copyCarts[hasItemIndex]?.totalPrice / product?.price + 1,
                        totalPrice: copyCarts[hasItemIndex]?.totalPrice + product?.price,
                    };

                    setCarts(copyCarts);
                }
            }
        });
    };

    const handleIncrementToCart = (productId) => {
        const updateCarts = carts?.map((cart) => {
            if (cart?.id === productId) {
                cart = {
                    ...cart,
                    quantity: (cart?.totalPrice + cart?.price) / cart?.price,
                    totalPrice: cart?.totalPrice + cart?.price,
                };
            }
            return cart;
        });

        handleDataLayer("add_to_cart", { ...ecommerce });

        setCarts(updateCarts);
    };

    const handleDecrementToCart = (productId) => {
        const updateCarts = carts?.map((cart) => {
            if (cart?.id === productId) {
                cart = {
                    ...cart,
                    quantity: (cart?.totalPrice - cart?.price) / cart?.price,
                    totalPrice: cart?.totalPrice - cart?.price,
                };
            }
            return cart;
        });

        const filterCarts = updateCarts?.filter((cart) => {
            if (cart?.totalPrice > 0) {
                return cart;
            }
        });

        handleDataLayer("remove_from_cart", { ...ecommerce });

        setCarts(filterCarts);
    };

    const getGrandTotalPrice = () => {
        const prices = carts?.map((cart) => cart.totalPrice);
        // const isFirstProduct = carts?.length === 1 && carts?.[0]?.totalPrice / carts?.[0]?.price === 1;

        // if (isFirstProduct) return 0;

        return prices?.length && prices?.reduce((acc, curr) => acc + curr);
    };

    const handleInputChange = (event) => {
        setAddressFormData({
            ...addressFormData,
            [event.target.name]: event.target.value,
        });
    };

    const handleRegInputChange = (event) => {
        setRegFormData({
            ...regFormData,
            [event.target.name]: event.target.value,
        });
    };

    const handleLoginInputChange = (event) => {
        setLoginFormData({
            ...loginFormData,
            [event.target.name]: event.target.value,
        });
    };

    const handleAddressFormSubmit = async () => {
        const { newErrors, hasError } = findShippingFormErrors(addressFormData);

        if (hasError) {
            setErrors(newErrors);
        } else {
            setErrors({});
            setLoading(true);
            try {
                Axios.defaults.withCredentials = true;
                const response = await Axios.post("/api/address", addressFormData);
                if (response.status === 201) {
                    handleDataLayer("add_shipping_info", { ...ecommerce, value: getGrandTotalPrice() });
                    setStep(6);
                    setLoading(false);
                }
            } catch (error) {
                if (error.response.status === 422) {
                    setIsAccountExist(false);
                    setLoading(false);
                }
            }
        }
    };

    const handleRegFormSubmit = async () => {
        const { newErrors, hasError } = findRegFormErrors(regFormData);

        if (hasError) {
            setErrors(newErrors);
        } else {
            setErrors({});
            setLoading(true);
            try {
                const regiResponse = await Axios.post("/register", regFormData);

                if (regiResponse.status === 201) {
                    Axios.defaults.withCredentials = true;
                    const corsResponse = await Axios.get("/sanctum/csrf-cookie");
                    if (corsResponse.status === 204) {
                        const loginResponse = await Axios.post("/login", {
                            email: regFormData?.email,
                            password: regFormData?.password,
                        });

                        if (loginResponse.status === 200) {
                            setIsEdit(false);
                            setStep(5);

                            window.dataLayer.push({
                                event: "login",
                            });
                            setLoading(false);
                        }
                    }
                }
            } catch (error) {
                setLoading(false);
                if (error.response.status === 422) {
                    setIsAccountExist(true);
                }
            }
        }
    };

    const handleLoginFormSubmit = async () => {
        const { newErrors, hasError } = findLoginFormErrors(loginFormData);

        if (hasError) {
            setErrors(newErrors);
        } else {
            setErrors({});
            setLoading(true);
            try {
                Axios.defaults.withCredentials = true;
                const corsResponse = await Axios.get("/sanctum/csrf-cookie");
                if (corsResponse.status === 204) {
                    const loginResponse = await Axios.post("/login", loginFormData);

                    if (loginResponse.status === 200) {
                        setServerError("");
                        setIsEdit(true);

                        window.dataLayer.push({
                            event: "login",
                        });
                        setIsSubscribed(false);
                        const addressResponse = await Axios.get("/api/address");

                        if (loginResponse?.data?.data?.subscription === true) {
                            setIsSubscribed(true);
                            setLoading(false);
                        } else {
                            setIsEdit(true);
                            setIsSubscribed(false);
                            const addressResponse = await Axios.get("/api/address");

                            if (addressResponse.status === 200) {
                                setLoading(false);
                                setStep(5);
                                handleDataLayer("add_shipping_info", { ...ecommerce, value: getGrandTotalPrice() });

                                const { country, phone, post_code, city, state, address, address2 } = addressResponse?.data?.data;
                                setAddressFormData({
                                    ...addressFormData,
                                    country,
                                    phone,
                                    post_code,
                                    city,
                                    state,
                                    address,
                                    address2,
                                });
                            }
                        }
                    }
                }
            } catch (error) {
                setLoading(false);
                setServerError(error?.response?.data?.message);
                if (error?.response?.status === 422) {
                    setIsAccountExist(false);
                }
            }
        }
    };

    const handleRemoveCart = (productId) => {
        let copyCarts = [...carts];
        copyCarts = copyCarts?.filter((item) => item?.id !== productId);

        setCarts(copyCarts);
    };
    return (
        <div className="page-wrapper">
            <div className="page-dialog">
                <div className="p-3 pb-4 pt-5 p-sm-5">
                    {step === 2 && (
                        <>
                            <div className="row">
                                <div className="col-lg-10">
                                    <div className="title px-1 px-sm-0">
                                        <div className="main-title text-center">Your Shopping Cart</div>
                                        <div className="main-text ">
                                            <p>
                                                Experience the plant intelligence of Yunnan Baiyao for <strong>free now</strong>
                                            </p>
                                            <p>
                                                Yunnan Baiyao is part of the official standard equipment of many Olympic delegations and Top Athletes around the world like <strong>Prof. Dr. med. Beat Knechtle.</strong> Since 1995, the Herbal Recipe received the official Chinese award "Most valuable herbal recipe in the whole country"
                                            </p>
                                            <p>
                                                <strong>Experience it for free now.</strong> Receive the first unit at no additional cost when you sign up for the monthly delivery subscription.
                                            </p>
                                            <p>
                                                Additionally, keep in mind that you are protected by our <strong>30-day money-back guarantee.</strong>
                                            </p>
                                            <p className="mb-1">
                                                <strong>More information about subscription:</strong>
                                            </p>
                                            <ul>
                                                <li>
                                                    - The first payment will be charged one month after you received your <strong>free shipment</strong> (if this is true)
                                                </li>
                                                <li>
                                                    - You can <strong>cancel the subscription</strong> any time
                                                </li>
                                            </ul>

                                            {/* <p>
                                            <strong>Yunnan Baiyao is part of the official standard equipment of many Olympic delegations and Top Athletes around the world like Prof. Dr. med. Beat Knechtle.</strong> Since 1995, the Herbal Recipe received the official Chinese award "Most valuable herbal recipe in the whole country".<br></br><br></br>
                                            <strong>Experience it for free now.</strong> Receive the first unit at no additional cost when you sign up for the monthly delivery subscription. <br /> <br />
                                            <strong>More information about subscription: </strong>
                                            <br />
                                            The first payment will be charged one month after you received your free shipment (if this is true)
                                            <br />
                                            You can cancel the subscription any time
                                            <br />
                                            <br />
                                            Additionally, keep in mind that you are protected by our <strong>30-day money-back guarantee.</strong>
                                        </p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="title-image d-none d-lg-block">
                                        <img alt="image" src="./images/China-Most-valuable-badge.png" />
                                    </div>
                                </div>
                            </div>
                            {/* List of my cart */}
                            {carts?.length > 0 ? (
                                <div className="product-section-wrapper table-responsive mt-3  mt-md-5">
                                    {/* <div className="heading-wrapper d-flex justify-content-between align-items-center">
                                        <div className="payment-title mb-4">Your Shopping Cart</div>
                                    </div> */}

                                    <table className="table card-table d-none d-sm-table px-3 mb-1 lh-base">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Product</th>
                                                <th>Quantity</th>
                                                <th>Action</th>
                                                <th className="text-end">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {carts?.map((cart, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {cart?.name} (<strong>Monthly Subscription</strong>)
                                                    </td>
                                                    <td className="cart-action">
                                                        <span onClick={() => handleDecrementToCart(cart?.id)}>-</span>
                                                        <span className="mx-2">{cart?.totalPrice / cart?.price}</span>
                                                        <span onClick={() => handleIncrementToCart(cart?.id)}>+</span>
                                                    </td>
                                                    <td className="remove-cart">
                                                        <img onClick={() => handleRemoveCart(cart?.id)} src="./images/delete.png" alt="Delete" />
                                                    </td>
                                                    <td className="text-end">&euro;{cart?.totalPrice.toLocaleString('en-DE', {minimumFractionDigits:2, maximumFractionDigits:2})} / month</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <h3 className="d-block d-sm-none" style={{ color: "#000", fontWeight: 700 }}>
                                        Product Information
                                    </h3>
                                    <table className="table card-table d-block d-sm-none px-0 px-md-3 mb-1 lh-base">
                                        <tbody>
                                            {carts?.map((cart, index) => (
                                                <tr key={index}>
                                                    <td style={{ width: "35px" }}>
                                                        <img style={{ width: "100%", height: "50px" }} alt="image" src={`./images/product2a.png`} />
                                                    </td>
                                                    <td style={{ textWrap: "inherit" }}>
                                                        {cart?.name} (<strong>Monthly Subscription</strong>)
                                                    </td>
                                                    <td className="cart-action">
                                                        <div>
                                                            <span onClick={() => handleDecrementToCart(cart?.id)}>-</span>
                                                            <span className="mx-2">{cart?.totalPrice / cart?.price}</span>
                                                            <span onClick={() => handleIncrementToCart(cart?.id)}>+</span>
                                                        </div>
                                                        {/* <div className="text-center fw-bold mt-1">
                                                            <strong>&euro;{cart?.totalPrice}/month</strong>
                                                        </div> */}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    {carts?.length > 0 && (
                                        <>
                                            <div className="d-none d-sm-flex align-items-center justify-content-between mb-3">
                                                <div className="text" style={{ fontSize: "13px", lineHeight: "20px" }}>
                                                    Receive the first unit at <strong>no additional cost</strong> when you sign up for the monthly delivery subscription. <br />
                                                    Additionally, keep in mind that you are protected by our <strong>30-day money-back guarantee.</strong>
                                                </div>
                                                <div className="total-ammount">Total: &euro;{getGrandTotalPrice().toLocaleString('en-DE', {minimumFractionDigits:2, maximumFractionDigits:2})} / month</div>
                                            </div>
                                            <div className="d-block d-sm-none align-items-center justify-content-between mb-3">
                                                <div className="mb-2 total-ammount d-flex align-items-center justify-content-between">
                                                    <span>Total:</span> <span>&euro;{getGrandTotalPrice().toLocaleString('en-DE', {minimumFractionDigits:2, maximumFractionDigits:2})} / month</span>
                                                </div>
                                                <div className="text" style={{ fontSize: "11px", lineHeight: "18px" }}>
                                                    Receive the first unit at <strong>no additional cost</strong> when you sign up for the monthly delivery subscription. Additionally, keep in mind that you are protected by our <strong>30-day money-back guarantee.</strong>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {carts?.length > 0 && (
                                        <div className="text-center py-4">
                                            <div className="cart-btn d-inline-block" style={{ borderRadius: "5px" }} onClick={() => setStep(3)}>
                                                Next
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="empty-msg py-3 text-center">You have no product on cart</div>
                            )}
                        </>
                    )}

                    {/* STEP: 02: Auth Register */}
                    {step === 3 && (
                        <div className="row">
                            <div className="col-md-9 m-auto">
                                <div className="form-block product-block">
                                    <div className="payment-title">Registration</div>
                                    <form name="lead-modal" className="row" id="register-form">
                                        <div className="text text-center fs-10 mb-4 lh-base">Please create an account so we can handle your free shipment and the subscription services.</div>
                                        <div className="is-account-exist">
                                            Already have an account?
                                            <span onClick={() => setStep(4)}>Login</span>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="name" placeholder="NAME*" required onChange={(event) => handleRegInputChange(event)} />
                                            {errors?.name && <div className="invalid-feedback d-block">{errors?.name}</div>}
                                        </div>

                                        <div className="form-group">
                                            <input type="email" name="email" placeholder="EMAIL ADDRESS*" required onChange={(event) => handleRegInputChange(event)} />
                                            {errors?.email && <div className="invalid-feedback d-block">{errors?.email}</div>}
                                        </div>

                                        <div className="form-group">
                                            <input type="password" name="password" placeholder="Password" required onChange={(event) => handleRegInputChange(event)} />
                                            {errors?.password && <div className="invalid-feedback d-block">{errors?.password}</div>}
                                        </div>

                                        <div className="form-group">
                                            <input type="password" name="password_confirmation" placeholder="Confirm password" required onChange={(event) => handleRegInputChange(event)} />
                                            {errors?.password_confirmation && <div className="invalid-feedback d-block">{errors?.password_confirmation}</div>}
                                        </div>

                                        {isAccountExist && <div className="error-message text-center pt-3">An account with this email already exists.</div>}

                                        <div className="text-center py-4">
                                            <div className="cart-btn d-inline-block" style={{ borderRadius: "5px" }} onClick={handleRegFormSubmit} type="submit">
                                                Registration
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* STEP: 03: Auth Login */}
                    {step === 4 && (
                        <div className="row">
                            <div className="col-md-9 m-auto">
                                <div className="form-block product-block">
                                    <div className="payment-title">Login</div>
                                    <form name="lead-modal" className="row" id="login-form">
                                        <div className="is-account-exist mb-4">
                                            Don't have an account?
                                            <span onClick={() => setStep(3)}>Register</span>
                                        </div>
                                        <div className="form-group">
                                            <input type="email" name="email" placeholder="EMAIL ADDRESS*" required onChange={(event) => handleLoginInputChange(event)} />
                                            {errors?.email && <div className="invalid-feedback d-block">{errors?.email}</div>}
                                        </div>

                                        <div className="form-group">
                                            <input type="password" name="password" placeholder="**********" required onChange={(event) => handleLoginInputChange(event)} />
                                            {errors?.password && <div className="invalid-feedback d-block">{errors?.password}</div>}
                                        </div>

                                        {serverError && <div className="invalid-feedback d-block">{serverError}</div>}

                                        <div className="text-center pt-4 pb-3">
                                            <div className="cart-btn d-inline-block" style={{ borderRadius: "5px" }} onClick={handleLoginFormSubmit} type="submit">
                                                Login
                                            </div>
                                        </div>

                                        <div className="is-account-exist">
                                            Forgot<span onClick={() => window.open("/forgot-password", "_self")}>password?</span>
                                        </div>

                                        {isSubscribed && (
                                            <div className="is-account-exist">
                                                You have already subscribed to a plan. <br />
                                                <span onClick={() => window.open("/login", "_self")}>Login</span> to check your order history and more ...
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* STEP: 04: Author Information */}
                    {step === 5 && (
                        <div className="row">
                            <div className="col-md-9 m-auto">
                                <div className="form-block product-block">
                                    <div className="payment-title mb-5">Shipping Information</div>
                                    <form name="lead-modal" className="row" id="address-form">
                                        <div className="form-group">
                                            <input type="number" name="phone" placeholder="PHONE NUMBER*" value={addressFormData?.phone || ""} required onChange={(event) => handleInputChange(event)} />
                                            {errors?.phone && <div className="invalid-feedback d-block">{errors?.phone}</div>}
                                        </div>

                                        <div className="form-group">
                                            <input type="text" name="address" value={addressFormData?.address || ""} placeholder="ADDRESS*" required onChange={(event) => handleInputChange(event)} />
                                            {errors?.address && <div className="invalid-feedback d-block">{errors?.address}</div>}
                                        </div>

                                        <div className="form-group">
                                            <input type="text" value={addressFormData?.address2 || ""} name="address2" placeholder="ADDRESS LINE 2" onChange={(event) => handleInputChange(event)} />
                                        </div>

                                        <div className="form-group">
                                            <input type="text" name="city" value={addressFormData?.city || ""} placeholder="CITY*" required onChange={(event) => handleInputChange(event)} />
                                            {errors?.city && <div className="invalid-feedback d-block">{errors?.city}</div>}
                                        </div>

                                        <div className="form-group">
                                            <input type="text" name="state" value={addressFormData?.state || ""} placeholder="STATE" onChange={(event) => handleInputChange(event)} />
                                        </div>

                                        <div className="form-group">
                                            <input type="text" name="post_code" value={addressFormData?.post_code || ""} placeholder="POSTAL CODE*" required onChange={(event) => handleInputChange(event)} />
                                            {errors?.post_code && <div className="invalid-feedback d-block">{errors?.post_code}</div>}
                                        </div>

                                        <div className="form-group">
                                            <select className="form-select" name="country" value={addressFormData?.country || ""} required onChange={(event) => handleInputChange(event)}>
                                                <option value="">Select Country</option>
                                                {euCountries?.map((country, index) => (
                                                    <option key={index} value={country?.code}>
                                                        {country?.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors?.country && <div className="invalid-feedback d-block">{errors?.country}</div>}
                                        </div>

                                        <div className="text-center py-4">
                                            <div
                                                className="cart-btn d-inline-block"
                                                style={{ borderRadius: "5px" }}
                                                onClick={() => {
                                                    handleAddressFormSubmit();
                                                    handleDataLayer("add_payment_info", { ...ecommerce, value: getGrandTotalPrice() });
                                                }}
                                                type="submit"
                                            >
                                                Next
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* STEP: 05: Payment Information */}
                    {step === 6 && (
                        <div className="row">
                            <div className="col-md-9 m-auto">
                                <div className="payment-gateway">
                                    <div className="text-center">
                                        <div style={{ width: "100%" }}>
                                            {/* Server error */}
                                            {serverError && <Alert variant="danger">{serverError}</Alert>}
                                        </div>
                                    </div>
                                    <PaymentForm
                                        payload={{
                                            plans: carts?.map((cart, idx) => {
                                                return {
                                                    plan: cart?.slug,
                                                    quantity: cart?.quantity,
                                                };
                                            }),
                                            totalPrice: getGrandTotalPrice(),
                                        }}
                                        handleSuccess={() => setStep(7)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {step === 7 && (
                        <div className="row">
                            <div className="col-md-9 m-auto">
                                <div className="success-process d-flex justify-content-center align-items-center text-center">
                                    <div>
                                        <img src="./images/success.png" alt="" />
                                        <p className="mt-3">Thank you for monthly subscription. Your ordered products will be delivered soon.</p>

                                        <p>Login to user dashboard to check your order history and more ...</p>

                                        <div className="text-center pt-4">
                                            <button
                                                className="cart-btn d-inline-block"
                                                onClick={() => window.open("/login", "_self")}
                                                style={{
                                                    borderRadius: "5px",
                                                    width: "initial",
                                                }}
                                            >
                                                Login
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {loading && (
                        <div className="loading-spinner">
                            <Spinner animation="border" variant="danger" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

let element = null;

document.addEventListener("DOMContentLoaded", function (event) {
    if (!element) {
        element = document.getElementById("check_out");
        const root = element && createRoot(element);

        if (element) root.render(<CheckoutSection />);
    }
});
