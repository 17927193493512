// Library import
import { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { ProductViewModal } from "../components/modals";

export const TryItFree = () => {
    const [showProductViewModal, setShowProductViewModal] = useState(false);

    // useEffect(() => {
    //     if (showProductViewModal) {
    //         document.body.style.position = "fixed";
    //         document.body.style.width = "100%";
    //     } else {
    //         document.body.style.position = "inherit";
    //         document.body.style.width = "inherit";
    //     }
    // }, [showProductViewModal]);

    return (
        <>
            <div className="action-btn pt-2 pb-3" onClick={() => window.open("/checkout?hasProduct=true", "_self")}>
                <button className="custom_button d-flex align-items-center justify-content-center">TRY FREE NOW</button>
            </div>

            {/* Product View Modal */}
            {showProductViewModal && <ProductViewModal show={showProductViewModal} handleClose={() => setShowProductViewModal(false)} />}
        </>
    );
};

let element = null;

document.addEventListener("DOMContentLoaded", function (event) {
    if (!element) {
        const dynamicClassName = "try_it_free";
        element = document.getElementsByClassName(dynamicClassName)[0];

        const root = element && createRoot(element);

        if (element) root.render(<TryItFree />);
    }
});
