// Library Import
import { useEffect, useState } from "react";
import Axios from "axios";
import { Toast, Spinner } from "react-bootstrap";

// File Import
import { findRegFormErrors } from "../../utils/validateForm";

export const UserAccountInfo = ({ user }) => {
    const [errors, setErrors] = useState({});
    const [formData, setFormdata] = useState({});
    const [successMsg, setSuccessMsg] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const { name, email } = user;
        setFormdata({
            ...formData,
            name,
            email,
        });
    }, []);

    const handleInputChange = (event) => {
        setFormdata({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const { newErrors, hasError } = findRegFormErrors(formData);

        if (hasError) {
            setErrors(newErrors);
        } else {
            setErrors({});
            try {
                setLoading(true);
                const response = await Axios.post("/api/users", formData);
                if (response.status === 204) {
                    setLoading(false);
                    setSuccessMsg("User information updated successfully");
                }
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }
    };
    return (
        <div className="form-block product-block px-0">
            <form name="lead-modal" className="row" id="user-update-form">
                <div className="form-group">
                    <input type="text" name="name" placeholder="NAME*" value={formData?.name || ""} required onChange={(event) => handleInputChange(event)} />
                    {errors?.name && <div className="invalid-feedback d-block">{errors?.name}</div>}
                </div>

                <div className="form-group">
                    <input type="email" name="email" value={formData?.email || ""} placeholder="EMAIL ADDRESS*" required onChange={(event) => handleInputChange(event)} />
                    {errors?.email && <div className="invalid-feedback d-block">{errors?.email}</div>}
                </div>

                <div className="form-group">
                    <input type="password" name="password" value={formData?.password || ""} placeholder="Password" required onChange={(event) => handleInputChange(event)} />
                    {errors?.password && <div className="invalid-feedback d-block">{errors?.password}</div>}
                </div>

                <div className="form-group">
                    <input type="password" value={formData?.password_confirmation || ""} name="password_confirmation" placeholder="Confirm password" required onChange={(event) => handleInputChange(event)} />
                    {errors?.password_confirmation && <div className="invalid-feedback d-block">{errors?.password_confirmation}</div>}
                </div>

                {loading && (
                    <div className="loading-spinner">
                        <Spinner animation="border" variant="danger" />
                    </div>
                )}

                <Toast bg="success" onClose={() => setSuccessMsg(null)} show={successMsg ? true : false} delay={3000} style={{ width: "80%", margin: "0px 15px" }} autohide>
                    <Toast.Body style={{ color: "white" }}>{successMsg}</Toast.Body>
                </Toast>

                <div className="text-center py-4">
                    <button disabled={loading} className="custom_button d-inline-block" style={{ borderRadius: "5px" }} type="submit" onClick={(event) => handleFormSubmit(event)}>
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};
