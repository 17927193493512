export const handleDataLayer = (event, data) => {
    handleClearDataLayer();
    window.dataLayer.push({ event, ecommerce: data || {} })
}

export const handleClearDataLayer = () => {
    for (let i = 0; i < window.dataLayer.length; i++) {
        if (window.dataLayer[i].ecommerce) {
            window.dataLayer[i].ecommerce = null;
        }
    }
};
