// Library import
import Axios from "axios";
import { useEffect, useState } from "react";
import { Alert, Modal, Spinner } from "react-bootstrap";

// File Import
import CrossIcon from "~/images/cross.png";

export const CancelSubscription = ({ show, handleClose, handleSuccess }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {}, [show]);

    const handleCancelSubscription = async () => {
        try {
            setLoading(true);
            const response = await Axios.delete("/api/subscriptions");

            if (response.status === 204) {
                setLoading(false);
                handleSuccess();
                handleClose();
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered animation={true} backdrop="static">
            <div className="p-3 p-sm-5 d-flex align-items-center justify-content-center">
                {loading && (
                    <div className="loading-spinner">
                        <Spinner animation="border" variant="danger" />
                    </div>
                )}
                <div className="modal-inner-content">
                    <h6 className="text-center fw-bold fs-6 pb-2">Are you sure want to delete ?</h6>
                    <div className="action-btn">
                        <button className="custom_button outline inline-block me-3" onClick={handleClose}>
                            BACK
                        </button>
                        <button className="custom_button inline-block" onClick={handleCancelSubscription}>
                            CANCEL
                        </button>
                    </div>
                </div>

                <div className="close-modal" onClick={handleClose}>
                    <img src={CrossIcon} alt="Cross" />
                </div>
            </div>
        </Modal>
    );
};
